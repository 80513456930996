<template>
    <el-dialog :visible="visible" width="600px" @close="handleClose" class="dialog" :title="title" :fullscreen="true">
        <el-form ref="form" :model="form" size="mini" label-position="top">
            <div style="display: flex;" v-if="reportList.length > 0">
                <div style="width: 240px">
                    <div v-for="(e, i) in reportList" :key="i">
                        <div v-for="(f, j) in e.files" :key="j" @click="setUrl(f.pdf_url)" class="nav">
                            {{e.created_at}} HRA风险评估报告
                        </div>
                    </div>
                </div>
                <div style="flex: 1">
                    <iframe :src="pdf_url" frameborder="0" width="100%"
                        style="width: 100%;height:calc(100vh - 80px)"></iframe>
                </div>
            </div>
            <el-empty v-else description="没有评估报告"></el-empty>
        </el-form>
    </el-dialog>
</template>

<script>
export default {
    props: {
        visible: {
            default: false
        },
        form: {
            default: {

            }
        },
        title: {
            default: "评估结果"
        },
    },
    data() {
        return {
            reportList: [],
            pdf_url: '',
        }
    },
    watch: {
        form: {
            immediate: true,
            handler(val) {
                if(val.id){
                    this.getData()
                }
            }
        }
    },
    methods: {
        onSubmit() {

        },
        setUrl(url) {
            this.pdf_url = url
        },
        getData() {
            this.pdf_url = ''
            this.$http.get(`/healthAssessment/jksc/pg_pdf/${this.form.id}`)
                .then(resp => {
                    if(resp.data.data) {
                        this.reportList = resp.data.data
                        if (this.reportList && this.reportList.length > 0) {
                            if(this.reportList[0].files) {
                                let f = this.reportList[0].files
                                this.pdf_url = f[0].pdf_url || ""
                            }
                        }
                    }
                }).catch(err => {
                    // this.$message.error(err.message)
                })
        },
        handleClose() {
            this.$emit('close')
        },
    },
    mounted() {
        // this.getData()
    }
}
</script>

<style lang="scss" scoped>
.dialog ::v-deep .el-dialog__body {
    padding: 0;
}
.nav {
    padding: 10px 20px;
    cursor: pointer;
}
</style>
