<template>
    <el-dialog :visible="visible" @close="handleClose" class="dialog" :title="title" :fullscreen="true">
        <el-tabs tab-position="left" style="height: calc(100vh - 80px);">
            <el-tab-pane label="评估问卷">
                <el-row>
                    <el-col :span="10" style="height: calc(100vh - 80px);overflow: scroll;padding:0 10px">
                        <el-form ref="form2" :model="form2" size="mini" label-position="top">
                            <el-form-item style="padding-top: 0px;text-align: right">
                                <div v-for="(e, i) in tableData" :key="i">
                                    <el-form-item style="padding-top: 0px;text-align: left">
                                        {{ i + 1 }}. {{ e.questionTitle }}
                                        <el-radio-group v-model="form2[e.questionTitle]">
                                            <el-radio :label="a.value" v-for="(a, j) in e.answerOption" border
                                                :key="j">{{ a.AnswerTxt }}</el-radio>
                                        </el-radio-group>
                                    </el-form-item>
                                </div>
                            </el-form-item>
                            <el-form-item style="padding-top: 40px;text-align: right">
                                <el-button type="primary" @click="handelPreview">预 览</el-button>
                                <el-button type="primary" @click="onSubmit">保 存</el-button>
                                <el-button @click="handleClose">取 消</el-button>
                            </el-form-item>
                        </el-form>
                    </el-col>
                    <el-col :span="14"
                        style="height: calc(100vh - 80px);overflow: scroll;padding:0 10px;border:1px solid #ccc">
                        <div v-if="Object.keys(report).length !== 0">
                            <myreport :report="report" :form="form"/>
                        </div>
                        <el-empty v-else description="请填写表单"></el-empty>
                    </el-col>
                </el-row>
            </el-tab-pane>
            <el-tab-pane label="健康报告">
                <el-empty description="暂无数据"></el-empty>
            </el-tab-pane>
            <el-tab-pane label="医生评估">
                <el-empty description="暂无数据"></el-empty>
            </el-tab-pane>
        </el-tabs>
    </el-dialog>
</template>

<script>
// import myreport from './myreport.vue'
import myreport from './personalAssessmentReport'
export default {
    components: {
        myreport
    },
    props: {
        visible: {
            default: false
        },
        form: {
            default: {
            }
        },
        title: {
            default: "评估详情"
        },
    },
    data() {
        return {
            report: {},
            tableData: [],
            loading: false,
            form2: {},
        }
    },
    watch: {
        form: {
            immediate: true,
            handler(val) {
                if(val.id){
                    this.getData()
                }
            }
        }
    },
    methods: {
        async handelPreview(){
            var va = false
            this.$refs.form2.validate((valid) => {
                if (valid) {
                    va = true
                }
            })
            if (!va) {
                return
            }
            try {
                let data = this.form
                data.result = this.form2
                const resp = await this.$http.post(`/healthProgress/pinggu/jksc`, data)
                this.$message[resp.data.status](resp.data.msg)
                this.report = resp.data.data
                this.form.score = this.report.score
            } catch (e) {
                this.$message.error(e.message)
            }
        },
        async onSubmit() {
            var va = false
            this.$refs.form2.validate((valid) => {
                if (valid) {
                    va = true
                }
            })
            if (!va) {
                return
            }
            try {
                let data = {
                    answer:this.form2,
                    user_id:this.form.id,
                    guardian:this.form.guardian,
                    current_risk_level:this.form.latest_risk_level
                }
                const resp = await this.$http.post(`/healthAssessment/jksc/form`, data)
                this.$message[resp.data.status](resp.data.msg)
                this.$emit('update')
            } catch (e) {
                this.$message.error(e.message)
            }
        },
        handleClose() {
            this.$emit('close')
        },
        async getData() {
            this.loading = true
            this.form2 = {}
            this.report = {}
            const resp = await this.$http.get(`/wenjuan?type=4&page=1&pageSize=2000`, { params: this.search })
            if (!resp.data) {
                this.$message.error(resp.data.msg)
            } else {
                this.tableData = resp.data.data
            }
            this.loading = false
        }
    },
    mounted() {
        this.getData()
    }
}
</script>

<style lang="scss" scoped>
.dialog ::v-deep .el-dialog__body {
    padding: 10px 20px;
}

::v-deep .el-radio-group {
    width: 100%;
}

::v-deep .el-radio--small.is-bordered {
    width: 100%;
    margin: 5px 0;
    /*display: flex;*/
    /*align-items: center;*/
}

::v-deep .el-checkbox {
    display: block;
    /* 或者 flex，使得checkbox占据整行 */
    white-space: normal;
    /* 允许文本换行 */
    word-break: break-all;
    /* 在长单词或URL内部进行断行 */
}

::v-deep .el-checkbox--small.is-bordered {
    width: 100%;
    margin: 5px 0;
    display: flex;
    align-items: center;
}

::v-deep .el-checkbox.is-bordered.el-checkbox--small {
    height: inherit;
}

.tijian {
    border: 1px solid #c0c4cc;
    padding: 5px;
}

.title {
    display: flex;
    align-items: center;
    padding: 10px;

    .title-text {
        text-align: center;
        border-bottom: 1px solid #EBEEF5;
        border-top: 1px solid #EBEEF5;
        padding: 10px;
    }
}

::v-deep .el-table .el-table__cell {
    padding: 6px 0;
}

.flex {
    display: flex;
}

.btn {
    justify-content: space-between;
    padding: 10px 0;
}

.border-bottom {
    border-bottom: 1px solid #EBEEF5;
}

.text-center {
    text-align: center;
    padding: 5px 0;
}

.align-items {
    align-items: center;
}

.text-group {
    display: grid;
    padding-right: 10px
}
</style>
