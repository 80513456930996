<template>
<!--    <el-dialog :visible="visible" width="800px" class="dialog" @close="handleClose">-->
    <div>
        <div >
            <div ref="print" style="padding: 20px;letter-spacing: 1px;font-family: 'SimSun', '宋体', Arial, sans-serif;" id="printTest">
                <el-row style="page-break-after:always">
                    <el-row class="text-title">
                        脑卒中风险评估
                    </el-row>
                    <el-row class="item-jbxx">
                        <div class="item-list" v-for="(item,index) in jbxxi" :key="index">
                            <div class="label">
                                <div style="display: flex;justify-content: space-between">
                                    <span v-for="(it,ids) in item.label" :key="ids">{{it}}</span>
                                </div>
                            </div>
                            <div class="label-value" v-if="item.fields=='sex'">
                                <span v-if="form[item.fields]=='1'">男</span>
                                <span v-if="form[item.fields]=='2'">女</span>
                            </div>
                            <div class="label-value" v-else>{{form[item.fields]}}</div>
                        </div>
                    </el-row>
                    <el-row>
                        <div class="first-footer">
                            本报告非医疗诊断文书，任何医疗行为请遵从医嘱
                        </div>
                    </el-row>
                </el-row>
                <el-row style="page-break-after:always">
                    <div class="two-page">
                        <div class="qianyan">前言</div>
                        <div class="title">
                            尊敬的{{form.name}}<span v-if="form.sex=='1'">男</span><span v-if="form.sex=='2'">女</span>士：
                        </div>
                        <div class="tw-title-text">您好！</div>
                        <div class="tw-title-text">
                            首先，感谢您对本机构的信赖。我们将竭诚为您提供优质、高效、专业的健康管理服务，为您的
                            健康与幸福保驾护航。
                        </div>
                        <div class="tw-title-text">
                            世界卫生组织（WHO）明确公告：“健康长寿，遗传占15%，社会因素占10%，医疗条件占8%，气
                            候条件占7%，而60%的成份取决于自己的生活方式和态度。”也就是说，每个人的生命掌握在自己的手
                            中。健康是您最宝贵的财富和资源，健康需要精细的管理。
                        </div>
                        <div class="tw-title-text">
                            健康管理是指对个体和群体的健康进行全面监测、分析、评估、提供健康咨询和指导以及对健康
                            危险因素进行干预的全过程。其宗旨是调动个体、群体及整个社会的积极性，有效地利用有限的资源来
                            达到最大的健康效果。健康管理的新理念是变人类健康被动管理为主动管理，并帮助人们科学地恢复健
                            康、维护健康、促进健康。
                        </div>
                        <div class="tw-title-text">
                            我们根据您的体检结果及健康问卷调查情况进行汇总分析，对您的健康状况提出综合评估结论和
                            健康干预建议，为您呈上此份个性化健康管理报告。
                        </div>
                        <div class="tw-title-text">
                            由于每个人遗传特质不同，体质差异，生活饮食习惯、运动多少的不同，个人生活调查问卷填写
                            的真实性、准确性、完整性均有不同，这些因素都会对本报告的结果产生影响。同时，此报告内容不可
                            作为诊断和治疗的依据。
                        </div>
                        <div class="tw-title-text">
                            健康从了解自己开始，希望您仔细阅读并妥善保存这份健康报告，关注报告中的提示与建议。由
                            于报告篇幅有限，很多方面的内容并未展示完整，请您按照本报告中的网址或二维码登录您的个人健康
                            空间查看更多详情。如果您对其中内容有任何疑问，可以拨打封底的电话咨询我们，也可以进行在线咨
                            询。
                        </div>
                        <div style="text-align: right;padding-top: 360px;padding-right:60px;font-weight: bold;font-size: 16px">
                            <div>庆油田总医院健康管理</div>
                            <div>2023年10月23日</div>
                        </div>
                    </div>
                </el-row>
                <el-row class="three-page twopage" style="page-break-after:always">
                    <div>
                        <div class="molu">目录</div>
                        <div class="title">健康信息摘要 /</div>
                        <el-row>
                            <el-col :span="12" class="col">P01 健康信息汇总</el-col>
                            <el-col :span="12" class="col">P02 整体健康状况</el-col>
                        </el-row>
                        <div class="title">疾病风险评估 /</div>
                        <el-row>
                            <el-col :span="12" class="col">P03 脑卒中患病风险评估</el-col>
                        </el-row>
                        <div class="title">日常保健建议 /</div>
                        <el-row>
                            <el-col :span="12" class="col">P04 生活方式分析</el-col>
                            <el-col :span="12" class="col">P05 饮食保健建议</el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12" class="col">P09 运动保健建议</el-col>
                        </el-row>
                        <el-row class="footers">
                            <div class="footer">
                                <div class="text-footers">
                                    <div>迎登录个人健康中心查询更多信息</div>
                                    <div>公众号： 大庆油田总医院健康管理中心</div>
                                    <div>账号： 2309061087</div>
                                    <div>密码： 123456</div>
                                </div>
                            </div>
                        </el-row>
                    </div>
                </el-row>
                <el-row class="four-page twopage" style="page-break-after:always">
                    <div>
                        <div class="molu">健康信息汇总</div>
                        <div class="title">主要健康问题 /</div>
                        <div class="col-docder">
                            <el-row class="col">
                                <el-col :span="3" class="col-1">体检异常</el-col>
                                <el-col :span="21" class="col-2">坐骨神经痛</el-col>
                            </el-row>
                            <el-row class="col">
                                <el-col :span="3" class="col-1">现患疾病</el-col>
                                <el-col :span="21" class="col-2">坐骨神经痛</el-col>
                            </el-row>
                            <el-row class="col0">
                                <el-col :span="3" class="col-1">家族病史</el-col>
                                <el-col :span="21" class="col-2">无</el-col>
                            </el-row>
                        </div>
                        <div class="title">生活方式情况 /</div>
                        <div class="col-docder">
                            <el-row class="col">
                                <el-col :span="12" class="col-1">
                                    <span>饮食: </span>
                                    <span>您的膳食结构基本合理</span>
                                </el-col>
                                <el-col :span="12" class="col-1">
                                    <span>运动: </span>
                                    <span>您的体力活动一般</span>
                                </el-col>
                            </el-row>
                            <el-row class="col">
                                <el-col :span="12" class="col-1">
                                    <span>吸烟: </span>
                                    <span>您从不吸烟，也无被动吸烟</span>
                                </el-col>
                                <el-col :span="12" class="col-1">
                                    <span>饮酒: </span>
                                    <span>饮酒: 您不饮酒</span>
                                </el-col>
                            </el-row>
                            <el-row class="col0">
                                <el-col :span="12" class="col-1">
                                    <span>睡眠:  </span>
                                    <span style="color: red">您的睡眠不足</span>
                                </el-col>
                                <el-col :span="12" class="col-1">
                                    <span>心理: </span>
                                    <span style="color: red">您有很大精神压力</span>
                                </el-col>
                            </el-row>
                        </div>
                        <div class="title">慢病患病风险 /</div>
                        <div class="col-docder">
                            <el-row class="col">
                                <el-col :span="3" class="col-1">很高</el-col>
                                <el-col :span="21" class="col-2">无</el-col>
                            </el-row>
                            <el-row class="col">
                                <el-col :span="3" class="col-1">高危 </el-col>
                                <el-col :span="21" class="col-2">无</el-col>
                            </el-row>
                            <el-row class="col">
                                <el-col :span="3" class="col-1">中危</el-col>
                                <el-col :span="21" class="col-2">无</el-col>
                            </el-row>
                            <el-row class="col0">
                                <el-col :span="3" class="col-1">低危</el-col>
                                <el-col :span="21" class="col-2">无</el-col>
                            </el-row>
                        </div>
                        <div class="title">重要指标情况 /</div>
                        <div class="col-docder">
                            <el-row class="col-th" style="text-align: center">
                                <el-col :span="8" class="col-1">指标名称</el-col>
                                <el-col :span="4" class="col-1">结果</el-col>
                                <el-col :span="4" class="col-1">正常参考</el-col>
                                <el-col :span="4" class="col-1">单位</el-col>
                                <el-col :span="4" class="col-1">需要注意</el-col>
                            </el-row>
                            <el-row :class="zbList.length == (index+1)?'col0':'col'" style="text-align: center" v-for="(items,index) in zbList" :key="index">
                                <el-col :span="8" class="col-1">{{items.name}}</el-col>
                                <el-col :span="4" class="col-1"> <span> &nbsp;</span> </el-col>
                                <el-col :span="4" class="col-1">&nbsp; </el-col>
                                <el-col :span="4" class="col-1">{{items.unit}}</el-col>
                                <el-col :span="4" class="col-1"> &nbsp; </el-col>
                            </el-row>
                        </div>
                    </div>
                </el-row>
                <el-row class="five-page twopage" style="page-break-after:always">
                    <div>
                        <div class="molu">整体健康状况</div>
                        <div class="title-text">以下我们将分析您本次的体检结果和健康调查信息，为您做一个综合健康评分，清晰展现危害您健
                            康的主要危险因素,并指出应该达到的目标或相关建议。
                        </div>
                        <div class="title">综合健康评分 /</div>
                        <div>
                            <el-row :gutter="20">
                                <el-col :span="7">
                                    <div class="zhjkpf">
                                        <div class="zhjkpf-title">您的综合健康评分为</div>
                                        <div class="zhjkpf-count">{{form.score||0}}</div>
                                    </div>
                                </el-col>
                                <el-col :span="17">
                                    <div class="zhjkpf-right">
                                        <div class="imags"><img src="../../../../assets/account.png" /></div>
                                        <div class="zhjkpf-right-xt">
                                            <div class="xt xt01">很差</div>
                                            <div class="xt xt02">差</div>
                                            <div class="xt xt03">较差</div>
                                            <div class="xt xt04">一般</div>
                                            <div class="xt xt05">很好</div>
                                        </div>
                                        <div class="zhjkpf-right-tag">
                                            <div class="xt xt00">90分以上</div>
                                            <div class="xt xt01">70-90 </div>
                                            <div class="xt xt02">50-70</div>
                                            <div class="xt xt03">50-30 </div>
                                            <div class="xt xt04">30分以下</div>
                                        </div>
                                    </div>
                                </el-col>
                            </el-row>
                            <div class="zhjkpf-text">
                                评分等级：>90分以上很差  70-90差   50-70较差  50-30一般  30分以下较好
                            </div>
                        </div>
                        <div class="title">主要危险因素 /</div>
                        <div>
                            <table border="0" cellspacing="0" cellpadding="0" width="100%"
                                   style="margin: auto !important;">
                                <tbody>
                                <tr>
                                    <th >类型</th>
                                    <th >危险因素</th>
                                    <th >管理建议</th>
                                </tr>
                                <tr v-for="(item,index) in tableData" :key="index">
                                    <td :class="[index==0?'zywxys-top':'',(index+1)==tableData.length?'zywxys-bottom':'']">{{item.name}}</td>
                                    <td :class="[index==0?'zywxys-top':'',(index+1)==tableData.length?'zywxys-bottom':'']">{{item.ys}}</td>
                                    <td :class="[index==0?'zywxys-top':'',(index+1)==tableData.length?'zywxys-bottom':'']">{{item.gljy}}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </el-row>
                <el-row class="five-page twopage" style="page-break-after:always">
                    <div>
                        <div class="molu">重要指标趋势</div>
                        <div class="title-text">以下我们将您历次体检的重要指标结果用趋势图展示，基础指标是我们健康水平的最佳反映指标 ,请关注自己的健康基准线，控制疾病进展，真正做到“我的健康我知道”。
                        </div>
                        <div class="title">体重指数 /</div>
                        <div class="title-text">【解释】又称“体质指数”，英文Body Mass  Index（BMI），是目前国际上常用的衡量人体胖瘦   程度以及是否健康的一个标准。大多数个体的体重指数与身体脂肪的百分含量有明显的相关性。具体 计算方法是体重(kg)除以身高(m)的平方。
                        </div>
                        <div class="title-text">
                        【判断】 ＜18.5为偏瘦，18.5~＜24为正常，24~＜28为超重，≥28为肥胖。
                        </div>
                    </div>
                </el-row>
                <el-row class="six-page twopage" style="page-break-after:always">
                    <div>
                        <div class="molu">脑卒中患病风险评估</div>
                        <div class="title-text">脑卒中是因脑部血管阻塞或破裂出血致脑血供障碍而迅速发展的脑功能损失。研究显示我国的脑卒
                            中正处于年轻化趋势。目前我国每年新发脑卒中的人数超过200万，发病率位居世界第一。
                        </div>
                        <div class="title">主要参数 /</div>
                        <div class="col-docder">
                            <el-row class="col-th" style="text-align: center">
                                <el-col :span="6" class="col-1">参数名称</el-col>
                                <el-col :span="6" class="col-1">本次结果</el-col>
                                <el-col :span="6" class="col-1">正常参考</el-col>
                                <el-col :span="6" class="col-1">需要注意</el-col>
                            </el-row>
                            <el-row :class="zbList.length == (index+1)?'col0':'col'" style="text-align: center" v-for="(items,index) in zyList" :key="index">
                                <el-col :span="8" class="col-1">{{items.name}}</el-col>
                                <el-col :span="4" class="col-1">无</el-col>
                                <el-col :span="4" class="col-1">无</el-col>
                                <el-col :span="4" class="col-1">无</el-col>
                                <el-col :span="4" class="col-1"></el-col>
                            </el-row>
                        </div>
                        <div class="title">评估结果 /</div>
                        <el-row :gutter="20">
                            <el-col :span="12">
                                <div class="pgjg-bar">
                                    <pgjg-bar :bardata="[]"></pgjg-bar>
                                </div>
                            </el-col>
                            <el-col :span="12">
                                <div class="zhjkpf-right">
                                    <div class="imags"><img src="../../../../assets/account.png" /></div>
                                    <div class="zhjkpf-right-xt">
                                        <div class="xt xt01">低风险</div>
                                        <div class="xt xt02">中风险</div>
                                        <div class="xt xt03">高风险</div>
                                        <div class="xt xt04">很高风险</div>
                                    </div>
                                    <div class="zhjkpf-right-tag">
                                        <div class="xt xt00">0</div>
                                        <div class="xt xt01">5</div>
                                        <div class="xt xt02">20</div>
                                        <div class="xt xt03">50</div>
                                        <div class="xt xt04">100</div>
                                    </div>
                                </div>
                                <div>
                                    <el-row class="pgjg-col">
                                        <el-col :span="8">本次绝对风险</el-col>
                                        <el-col :span="4">28.36</el-col>
                                        <el-col :span="8">人群平均风险</el-col>
                                        <el-col :span="4">0.3</el-col>
                                        <el-col :span="8">您的最佳状态</el-col>
                                        <el-col :span="4">10.48</el-col>
                                        <el-col :span="8">通过努力可降</el-col>
                                        <el-col :span="4">17.88</el-col>
                                    </el-row>
                                </div>
                            </el-col>
                        </el-row>
                        <div class="pgjg-title-text">
                            【结果】您在未来5~10年脑卒中的患病风险为28.36%，患病可能性较高，请立即行动起来，改正不良习惯。
                        </div>
                        <div class="title">预防要点 /</div>
                        <div class="title-text">
                            【饮食】饮食宜清淡，可多食用富含维生素及膳食纤维的食物以刺激肠蠕动，防止便秘，例如蔬菜、
                            水果等。少食含动物脂肪和胆固醇高的食物，如肥肉、蛋黄等。
                            <br>
                            【运动】头部前后左右旋转的运动，可增加血管的抗压力，有利于预防脑卒中。做法是：平坐，放松
                            颈部肌肉，然后前后左右摇头晃脑各做30～50次，速度宜慢，每天早晚各做3次。
                            <br>
                            【宣教】高血压、糖尿病是引起脑卒中的重要危险因素，要定期体检，及时发现早治疗。
                        </div>
<!--                        <div class="pgjg-title-text">-->
<!--                            【提示】1-->
<!--                        </div>-->

                    </div>
                </el-row>
                <el-row class="seven-page twopage" style="page-break-after:always">
                    <div>
                        <div class="molu">生活方式分析</div>
                        <div class="title-text">
                            根据医学研究证实，许多个人行为和生活因素会预示并影响着健康的趋势和寿命。对您目前的生活
                            方式信息进行汇总分析后，生成了如下报告，为您展示了目前的生活方式因素是如何潜在地影响您的身
                            体健康。希望您通过阅读此报告，发现不健康习惯，开始采取行动，控制健康风险。
                        </div>
                        <div class="title">生活方式评分 /</div>
                        <el-row style="display: flex">
                            <el-col :span="8">
                                <shfs-pie :bardata="[]"></shfs-pie>
                            </el-col>
                            <el-col :span="16" class="shfs">
                                <div>
                                    <div class="shfs-title"><span style="color: #67C23A">。</span>问卷填写日期：2023-09-13</div>
                                    <div class="shfs-text">您本次生活方式评分[一般]请参考建议改善不良生活习惯!</div>
                                </div>
                            </el-col>
                        </el-row>
                        <div class="title">主要分析建议 /</div>
                        <el-row>
                            <el-col :span="12">
                                <div class="zyfxjy">
                                    <div class="zyfxjy-title">饮食/Diet：</div>
                                    <div class="zyfxjy-title-text">
                                        <div class="zyfxjy-small-title">
                                            您的膳食结构基本合理
                                        </div>
                                        <div class="zyfxjy-text">
                                            您懂得膳食结构与健康的密切关系，膳
                                            食结构不合理是糖尿病、高血压、高脂
                                            血症等慢性病高发的主要危险因素。请
                                            参考后面的饮食促进方案进行调整，让
                                            自己吃得合理，吃出健康来。
                                        </div>
                                    </div>
                                </div>
                            </el-col>
                            <el-col :span="12">
                                <div class="zyfxjy">
                                    <div class="zyfxjy-title">运动/Sport：</div>
                                    <div class="zyfxjy-title-text">
                                        <div class="zyfxjy-small-title">
                                            您的体力活动一般
                                        </div>
                                        <div class="zyfxjy-text">
                                            您需要增加一些体力活动量，体力活动
                                            能够预防很多慢性疾病，包括冠心病、
                                            高血压、2型糖尿病、骨质疏松、精神
                                            抑郁和焦虑等。规律的体力活动还能加
                                            强各年龄段人们的生活质量。
                                        </div>
                                    </div>
                                </div>
                            </el-col>
                        </el-row>
                    </div>
                </el-row>
                <el-row class="eight-page twopage" style="page-break-after:always">
                    <div>
                        <div class="molu">饮食保健建议</div>
                        <div class="title-text">
                            饮食总原则为食物多样，谷类为主，粗细搭配。多吃蔬菜水果薯类。常吃适量的鱼、禽、蛋和瘦肉
                            等。减少烹调油用量，吃清淡少盐膳食。规律饮食、食不过量，吃新鲜卫生的食物。
                        </div>
                        <div class="title">营养摄入参考 /</div>
                        <el-row style="display: flex">
                            <el-col :span="12">
                                <div>
                                    <img src="../../../../assets/yysr.png" style="width: 300px"/>
                                </div>
                            </el-col>
                            <el-col :span="12" class="shfs">
                                <div>
                                    <div class="shfs-title">
                                        <span style="color: #67C23A">.</span>
                                        <span class="label">碳水化合物:</span>
                                        <span class="value">360.0克</span>
                                        <span class="value">------</span>
                                        <span class="value">60%</span>
                                    </div>
                                    <div class="shfs-title">
                                        <span style="color: #67C23A">.</span>
                                        <span class="label">碳水化合物:</span>
                                        <span class="value">360.0克</span>
                                        <span class="value">------</span>
                                        <span class="value">60%</span>
                                    </div>
                                    <div class="shfs-title">
                                        <span style="color: #67C23A">.</span>
                                        <span class="label">碳水化合物:</span>
                                        <span class="value">360.0克</span>
                                        <span class="value">------</span>
                                        <span class="value">60%</span>
                                    </div>
                                </div>
                            </el-col>
                        </el-row>
                        <div class="title">饮食分类建议 /</div>
                        <div class="col-docder">
                            <el-row class="col-th" style="text-align: center">
                                <el-col :span="4" class="col-1">类型 </el-col>
                                <el-col :span="7" class="col-1">目前每天摄入量</el-col>
                                <el-col :span="7" class="col-1">推荐每天摄入量 </el-col>
                                <el-col :span="6" class="col-1">建议</el-col>
                            </el-row>
                            <el-row :class="zbList.length == (index+1)?'col0':'col'" style="text-align: center" v-for="(items,index) in ysList" :key="index">
                                <el-col :span="4" class="col-1">{{items.name}}</el-col>
                                <el-col :span="7" class="col-1">{{items.mqsrl}}</el-col>
                                <el-col :span="7" class="col-1">{{items.jysrl}}</el-col>
                                <el-col :span="6" class="col-1">{{items.jy}}</el-col>
                            </el-row>
                        </div>
                        <div class="pgjg-title-text">
                            【注】1碗≈2两（100克），1杯≈200ml（200克），1个≈1两（50克）
                        </div>
                        <div class="title">饮食习惯分析 /</div>
                        <el-row >
                            <el-col :span="8" >
                                <div class="ysxgfx"><img src="../../../../assets/ysxg.png">经常不吃早餐</div>
                            </el-col>
                            <el-col :span="8" >
                                <div class="ysxgfx">经常不吃早餐</div>
                            </el-col>
                            <el-col :span="8" >
                                <div class="ysxgfx">经常不吃早餐</div>
                            </el-col>
                        </el-row>
                        <div class="pgjg-title-text">
                            【注】以上为所有您需要注意的饮食要点，其中<img src="../../../../assets/ysxg.png"> 表示您目前存在的不良饮食习惯。
                        </div>
                        <div class="title">膳食方案 /</div>
                        <div style="font-size: 14px;padding: 10px 0">
                            创建日期:2023-10-23
                            <div style="float: right">有效期: 3天</div>
                        </div>
                        <div class="col-docder">
                            <el-row class="col-th" style="text-align: center">
                                <el-col :span="4" class="col-1">食谱1  </el-col>
                                <el-col :span="14" class="col-1">食物</el-col>
                                <el-col :span="6" class="col-1">能量 </el-col>
                            </el-row>
                            <el-row :class="zbList.length == (index+1)?'col0':'col'" style="text-align: center" v-for="(items,index) in spList" :key="index">
                                <el-col :span="4" class="col-1">{{items.name}}</el-col>
                                <el-col :span="14" class="col-1">{{items.sw}}</el-col>
                                <el-col :span="6" class="col-1">
                                    <div>{{items.nl}}</div>
                                    <div>{{items.bl}}</div>
                                </el-col>
                            </el-row>
                        </div>
                        <div class="title">饮食注意事项 /</div>
                        <div class="yszysx">
                            1.少食肉、禽类、干豆、龙须菜、鲜豌豆、菠菜、蘑菇等，限制盐的摄入。<br>
                            2.忌食咸蛋、咸肉、咸鱼、酱菜、面酱、腊肠、腌制食物、含盐量不明的调味品。<br>
                            3.宜食用薯类，豆类，蔬菜，水果，富含膳食纤维的食物如大豆，黑色、绿色食品，木耳，香菇等
                            。<br>
                            4.坚持每天吃早餐，不宜过早，误伤肠胃；不宜过于营养，超过消化能力导致胃肠疾病，引起肥胖
                            。<br>
                            5.每人每天盐摄入量不超过6克，盐的摄入量与血压呈正比，摄入过多易导致高血压。<br>
                            6.吃饭前后半小时勿喝水，会稀释唾液和胃液，影响消化吸收，久之会导致身体健康状况不良。<br>
                            7.吃饭不宜过快，进食过快不容易嚼细食物，增加胃的消化负担，影响营养吸收，甚至损伤胃粘膜
                            。<br>
                            8.葱内的蒜辣素可抑制癌细胞的生长，含有微量元素硒，可降低胃液内的亚硝酸盐含量，预防胃癌
                            。<br>
                            9.番茄的酸性很高，可能刺激胃产生更多的胃酸。因此，吃太多番茄会导致泛酸、烧心等症状。<br>
                            10.吃完火锅，建议喝一些茶，这样有助于脂类食物的消化和排出，减少脂类物质在肠道的吸收。
                        </div>
                        <div class="title">食物交换份 /</div>
                        <div class="title-text">
                            食物多样化是《中国居民膳食指南》强调的重点，专家推荐的食谱成分是可以找同类食物代替的。
                            下表列举常见食物产生90千卡热量的食物份量供参考。
                        </div>
                        <div class="swjh">
                            <img src="../../../../assets/swjh.png" />
                        </div>
                    </div>
                </el-row>
                <el-row class="nine-page twopage" style="page-break-after:always">
                    <div>
                        <div class="molu">运动保健建议</div>
                    </div>
                    <div class="title-text">
                        生命在于运动，运动贵在坚持。适当的运动可以控制体重，提高免疫力，还能有效地缓解抑郁和压
                        力。以下根据本次体检及健康调查，对您的运动状况进行分析并定制了运动保健方案。
                    </div>
                    <div class="title">总体建议 /</div>
                    <el-row style="display: flex;align-items: center">
                        <el-col :span="10">
                            <div>
                                <img src="../../../../assets/ztjy.png" style="width: 260px"/>
                            </div>
                        </el-col>
                        <el-col :span="14" class="shfs">
                            <div>
                                <div class="shfs-title">
                                    <div style="color: #67C23A">.</div>
                                    <div class="label">根据心率控制:</div>
                                    <div class="value">运动时心率控制在87~112次/分</div>
                                </div>
                                <div class="shfs-title">
                                    <div style="color: #67C23A">.</div>
                                    <div class="label">根据感觉判断:</div>
                                    <div class="value">（RPE9~13）自我感觉从很轻松到有些吃力略微出汗。</div>
                                </div>
                                <div class="shfs-title">
                                    <div style="color: #67C23A">.</div>
                                    <div class="label">推荐量说明:</div>
                                    <div class="value">相当于以4千米/小时的速度步行78分钟</div>
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                    <div class="title">现状分析 /</div>
                    <div class="col-docder" style="border-top:1px solid #67C23A">
                        <el-row class="col">
                            <el-col :span="3" class="col-1">体重分析</el-col>
                            <el-col :span="21" class="col-2"></el-col>
                        </el-row>
                        <el-row class="col">
                            <el-col :span="3" class="col-1">相关异常</el-col>
                            <el-col :span="21" class="col-2">高血压；运动中谨记相关注意事项，做到安全健康运动。</el-col>
                        </el-row>
                        <el-row class="col0">
                            <el-col :span="3" class="col-1">运动现状</el-col>
                            <el-col :span="21" class="col-2">偶尔（1-2次/周）,每次30~<60分钟,中度运动；在保证运动安全的同时，可通过增加
                                时间或频率的方法来逐步增加运动量直至达到既定目标，并养成规律运动习惯。
                            </el-col>
                        </el-row>
                    </div>
                    <div class="title">自觉疲劳分级（RPE）表 /</div>
                    <div class="title-text">
                        本表有瑞典心理学家Borg提出，可用于运动强度的自我评估。运动后即刻读10秒脉搏数，再乘以
                        6可得每分钟心率，再除以10即为疲劳分级结果。对应下表，一般运动强度保持在10~14之间，不宜超
                        过16。
                    </div>
                    <div>
                        <el-col :span="24">
                            <div class="zhjkpf-right">
                                <div class="zhjkpf-right-xt">
                                    <div class="xt xt01">非常轻</div>
                                    <div class="xt xt02">很轻</div>
                                    <div class="xt xt03">有点累</div>
                                    <div class="xt xt04">稍累</div>
                                    <div class="xt xt05">累</div>
                                    <div class="xt xt06">很累</div>
                                    <div class="xt xt07">非常累</div>
                                </div>
                                <div class="zhjkpf-right-tag">
                                    <div class="xt xt00">6</div>
                                    <div class="xt xt01">8</div>
                                    <div class="xt xt02">10</div>
                                    <div class="xt xt03">12</div>
                                    <div class="xt xt04">14</div>
                                    <div class="xt xt05">16</div>
                                    <div class="xt xt06">18</div>
                                    <div class="xt xt07">20</div>
                                </div>
                            </div>
                        </el-col>
                    </div>
                    <div class="title">运动注意事项 /</div>
                    <div class="yszysx">
                        1.跑步前先预热身体十分钟，散步、甩臂，拉伸身体各部位的肌肉组织，先慢后快地进行运动。<br>
                        2.运动中不宜过于用力或屏气，不宜做速度强度大、精神紧张的项目；不“省略”整理活动：运动
                        感觉心力俱乏时，应适宜放松，有助于消除疲劳，快速恢复体力；不宜用嘴呼吸，应养成用鼻子呼
                        吸的习惯，使气管和肺部不受尘埃、病菌的侵害。<br>
                        3.运动后，不宜立即蹲坐休息、不在大汗淋漓时洗冷热水浴，沐浴一般应在20分钟内为宜；不贪吃
                        冷饮，不大量喝水吃糖，不立即吃饭；不宜立即吸烟或饮酒；不宜立即走进空调房或风口纳凉，避
                        免打破正常的生理调节机能。<br>
                        4.注意运动安全防护，观察运动场所的周边环境，并采取适当的安全护具；室内运动时，光线要适
                        宜，温度应保持20摄氏度左右，保持良好的通风环境。<br>
                        5.运动要避免受伤，科学锻炼，掌握正确的运动要领，预防运动伤病。<br>
                        6.临睡前不宜过度运动，避免大量出汗，17时至19时是晚间锻炼的最佳时段。<br>
                        7.可携带糖果，若有低血糖反应，可进食少量糖块；若有心绞痛症状，则应立即就医。<br>
                        8.如果有条件，最好在运动前后监测血压和心电图。
                    </div>
                    <div class="title">一次运动训练过程 /</div>
                    <div></div>
                    <div class="title">运动处方 /</div>
                    <div class="col-docder" style="border-top:1px solid #67C23A">
                        <el-row class="col">
                            <el-col :span="3" class="col-1">频率</el-col>
                            <el-col :span="21" class="col-2">有氧运动：每周至少5天；抗阻运动：每周对每个大肌群训练2~3d，并且同一肌群的练
                                习时间应至少间隔48h；柔韧性练习：每周至少2~3次，每天练习，效果更好。
                            </el-col>
                        </el-row>
                        <el-row class="col">
                            <el-col :span="3" class="col-1">强度</el-col>
                            <el-col :span="21" class="col-2">强度</el-col>
                        </el-row>
                        <el-row class="col">
                            <el-col :span="3" class="col-1">时间</el-col>
                            <el-col :span="21" class="col-2">有氧运动：每天累计至少30~60min（每周至少150min）；抗阻运动：每一肌群练习
                                2~4组，每组重复8~12次，组间休息2~3min；柔韧性练习：静力拉伸保持10~30s。</el-col>
                        </el-row>
                        <el-row class="col0">
                            <el-col :span="3" class="col-1">方式
                            </el-col>
                            <el-col :span="21" class="col-2">需要最少技能或体适能的耐力活动
                            </el-col>
                        </el-row>
                    </div>
                    <div class="title">推荐运动项目 /</div>
                    <el-row :gutter="20">
                        <el-col :span="12">
                            <div class="col-docder">
                                <el-row class="col-th" style="text-align: center">
                                    <el-col :span="14" class="col-1">运动项目  </el-col>
                                    <el-col :span="10" class="col-1">时间（分）</el-col>
                                </el-row>
                                <el-row :class="zbList.length == (index+1)?'col0':'col'" style="text-align: center" v-for="(items,index) in ydList1" :key="index">
                                    <el-col :span="14" class="col-1">{{items.name}}</el-col>
                                    <el-col :span="10" class="col-1">{{items.time}}</el-col>
                                </el-row>
                            </div>
                        </el-col>
                        <el-col :span="12">
                            <div class="col-docder">
                                <el-row class="col-th" style="text-align: center">
                                    <el-col :span="14" class="col-1">运动项目  </el-col>
                                    <el-col :span="10" class="col-1">时间（分）</el-col>
                                </el-row>
                                <el-row :class="zbList.length == (index+1)?'col0':'col'" style="text-align: center" v-for="(items,index) in ydList" :key="index">
                                    <el-col :span="14" class="col-1">{{items.name}}</el-col>
                                    <el-col :span="10" class="col-1">{{items.time}}</el-col>
                                </el-row>
                            </div>
                        </el-col>
                    </el-row>
                    <div class="pgjg-title-text">
                        【注】图中所示项目为优先推荐项目，列表中为备选项目。时间指相当于推荐运动量的运动时间。请根据个人喜好
                        和条件选择合适的运动项目
                    </div>
                </el-row>
                <el-row style="page-break-after:always">
                    <div class="two-page">
                        <div class="qianyan">结束语</div>
                        <div class="tw-title-text">
                            《健康中国2030规划纲要》强调“健康是促进人的全面发展的必然要求，是经济社会发展的基础
                            条件，是国家富强、民族振兴的重要标志”。

                        </div>
                        <div class="tw-title-text">
                            影响健康的因素很多，包括遗传、日常生活习惯、社会环境、医疗资源等等，其中医疗资源,仅占
                            影响健康因素的8%，最大的影响因素还是日常生活习惯及社会环境。世界卫生组织的数据显示，75%的
                            癌症是已知的环境因素和不良习惯所引起，而这些因素都是可以预防的，例如抽烟、酗酒。
                        </div>
                        <div class="tw-title-text">
                            很多人把钱投入治疗上，特别是临终前的抢救上，这些治疗费用占整个医疗费用的70%以上。但是
                            收益率最高的健康投资却是参加健康管理，控制各自的健康危险因素，降低疾病风险，优先预防疾病。
                        </div>
                        <div class="tw-title-text">
                            而健康检查不仅可以使健康人群加深对自我身体机能的了解，改变不良生活习惯，避免导致疾病
                            的危险因子产生，更重要的是可以帮助人们科学地了解和维护健康，最大限度地降低疾病的困扰与经济
                            负担。
                        </div>
                        <div class="tw-title-text">
                            我们愿以卓越的服务、优质的技术继续为您提供医疗保健服务，从体检中发现健康问题，从保健
                            中解决健康问题。我们衷心祝愿您身体更加健康，希望能与您携手朝着健康的目标共同迈进。

                        </div>
                        <div class="tw-title-text">
                            最后，感谢您对我们的支持，别忘了定期为自己和家人安排健康检查。
                        </div>
                    </div>
                </el-row>
            </div>
        </div>
        <div class="buttons">
            <el-button class="btn" @click="handleClose()">取 消</el-button>
            <el-button class="btn" type="primary"  @click="handelprint">打印报告</el-button>
        </div>
    </div>
<!--    </el-dialog>-->
</template>

<script>
    import pgjgBar from './pgjgBar'
    import shfsPie from './shfsPie'
    export default {
        components: {
            pgjgBar,
            shfsPie
        },
        props: {
            visible: {
                default: false
            },
            form: {
                default: {}
            },
        },
        data() {
            return {
                print: {
                    id: 'printTest',
                },
                printFooter: null,
                ydList:[
                    {name:"擦窗户",time:"63"},
                    {name:"逛街",time:"70"},
                    {name:"滑雪",time:"21"},
                    {name:"轮滑旱冰",time:"25"},
                    {name:"慢跑",time:"25"},
                ],
                ydList1:[
                    {name:"爬山（上山5.5km/h）",time:"29"},
                    {name:"上楼",time:"22"},
                    {name:"少林拳",time:"15"},
                    {name:"射箭",time:"45"},
                    {name:"手洗衣服",time:"53"},
                ],
                spList:[
                    {name:"早餐",sw:"烤馒头(馒头)，草莓杏仁奶(草莓、杏仁、牛乳)，甜拌白菜 心(大白菜、胡萝卜（红）)",nl:"479.5kcal","bl":"24.9%"},
                    {name:"午餐",sw:"蚝油生菜(生菜（牛俐）)，鱿鱼茶树菇(鱿鱼（水浸）、茶   树菇（干）)，赤小豆玉米饭(赤小豆、稻米)，家常豆腐(豆 腐（北豆腐）、辣椒（红，尖，干）)",nl:"954.22kcal","bl":"49.6%"},
                    {name:"晚餐",sw:"蘑菇焖牛肉(蘑菇（鲜蘑）、牛肉（肥瘦）、番茄、小葱\n" +
                            ")，瓜烧白菜(黄鱼（大黄花鱼）、大白菜（小白口）、小麦 粉（标准粉）、鸭蛋)，红枣燕麦黑米粥(枣（干）、黑米、 枸杞菜)\n",nl:"491.9kcal","bl":"25.5%"},
                    {name:"成分分析",sw:"能量:1925.6kcal    碳水化合物:302.6g   脂肪:40.9g    蛋白质:102.2g"},
                ],
                tableData:[
                    {name:"家族史",ys:"母亲：高血压病",gljy:"注意防患相关疾病"},
                    {name:"饮食是否规律",ys:"是",gljy:"应规律饮食，避免饮食过量或消化不良"},
                    {name:"运动",ys:"运动锻炼次数不足",gljy:"保证足够的运动频率和运动时间"},
                    {name:"吸烟情况",ys:"吸烟",gljy:"戒烟，以保护呼吸道和肺"},
                    {name:"吸烟情况",ys:"有被动吸烟",gljy:"远离吸烟环境，减少二手烟危害"},
                    {name:"既往接触史",ys:"很少接触辐射",gljy:"注意做好防范措施，远离辐射场所"},
                ],
                zbList:[
                    {name:"尿酸",unit:"μmol/L"},
                    {name:"葡萄糖",unit:"μmol/L"},
                    {name:"甘油三酯",unit:"μmol/L"},
                    {name:"低密度脂蛋白胆固醇",unit:"μmol/L"},
                    {name:"高密度脂蛋白胆固醇",unit:"μmol/L"},
                    {name:"体重指数",unit:"μmol/L"},
                    {name:"总胆固醇",unit:"μmol/L"},
                    {name:"糖化血红蛋白",unit:"%"},
                    {name:"同型半胱氨酸",unit:"μmol/L"},
                ],
                zyList:[
                    {name:"中风家族史",unit:"μmol/L"},
                    {name:"中风相关疾病",unit:"μmol/L"},
                    {name:"高血压病",unit:"μmol/L"},
                    {name:"糖尿病",unit:"μmol/L"},
                    {name:"血脂异常",unit:"μmol/L"},
                    {name:"体重指数",unit:"μmol/L"},
                    {name:"同型半胱氨酸",unit:"μmol/L"},
                    {name:"每次运动锻炼时间",unit:"μmol/L"},
                    {name:"吸烟状态",unit:"%"},
                ],
                ysList:[
                    {name:"谷薯",mqsrl:"2~<4碗",jysrl:"3.25碗/6.5两/325克",jy:"继续保持"},
                    {name:"肉类",mqsrl:"1~<2两",jysrl:"1~<2两",jy:"继续保持"},
                    {name:"鱼虾",mqsrl:"1~<2两",jysrl:"1~<2两",jy:"继续保持"},
                    {name:"蛋类",mqsrl:"吃",jysrl:"1个/45克",jy:"继续保持"},
                    {name:"奶类",mqsrl:"<1杯",jysrl:"2杯/400ml",jy:"适量增加"},
                    {name:"豆类",mqsrl:"0.5~<1两",jysrl:"0.6两/30克",jy:"继续保持"},
                    {name:"蔬菜",mqsrl:"2~<6两",jysrl:"8两/400克",jy:"适量增加"},
                    {name:"水果",mqsrl:"1~<4两",jysrl:"5.5两/275克",jy:"适量增加"},
                    {name:"饮水",mqsrl:"1~<2杯",jysrl:"9~<12杯",jy:"适量增加"},
                ],
                jbxxi: [
                    {
                        label: "体检编号",
                        fields: ""
                    },
                    {
                        label: "姓名",
                        fields: "name"
                    },
                    {
                        label: "性别",
                        fields: "sex"
                    },
                    {
                        label: "年龄",
                        fields: "age"
                    },
                    {
                        label: "单位",
                        fields: "companyName"
                    },
                    {
                        label: "体检日期",
                        fields: ""
                    },
                ],
            }
        },
        methods: {
            handelprint(){
                this.$print(this.$refs.print)
            },
            handleClose() {
                this.$emit('close')
            },
        }
    }
</script>

<style lang="scss" scoped>
    table {
        border-collapse: collapse;
    }

    table tbody tr {
        line-height: normal !important;
        font-size: 14px;
    }

    table tbody td {
        padding: 10px;
        border: 1px solid #ebebeb;
        text-align: center;
    }

    table tbody th {
        padding: 10px;
        border-top: 1px solid #67C23A;
        text-align: center;
    }
    .text-title {
        font-weight: bold;
        font-size: 55px;
        text-align: center;
        color: black;
        padding-top: 200px;
        padding-bottom: 100px;
    }

    .item-jbxx {
        padding-top: 80px;
        .item-list {
            margin-left: auto;
            margin-right: auto;
            width: 400px;
            padding: 10px 0;
            display: flex;
            color: black;
            font-size: 15px;

            .label {
                width: 80px;
                font-weight: bold;
                padding-right: 8px;
                color: black;
            }

            .label-value {
                text-align: center;
                height: 20px;
                font-size: 14px;
                width: 100%;
                border-bottom: 1px solid black;
            }
        }

    }
    .first-footer{
        padding-top: 280px;
        text-align: center;
    }
    .two-page {
        /*display: flex;*/
        align-items: center;
        color: black;
        padding-top: 50px;
        line-height: 23px;
        font-size: 15px;

        .tw-title-text {
            padding: 2px 0px;
            text-indent: 40px;
        }
        .qianyan{
            padding-bottom: 20px;
            text-align: center;
            font-size: 30px;
            font-weight: bold
        }
    }
    .twopage{
        font-size: 18px;
        .col-th{
            border-top: 1px solid #67C23A;
            border-bottom: 1px solid #67C23A;
            padding: 10px;
        }
        .col-docder{
            border-bottom: 1px solid #67C23A;
        }
        .molu{
            font-weight: bold;
            text-align: center;
            font-size: 40px;
            color: #67C23A;
            padding-top: 60px;
            padding-bottom: 30px;
        }
        .title{
            color: #67C23A;
            padding: 20px 10px  10px;
        }

        .title-text{
            line-height: 25px;
            font-size: 15px;
            padding-bottom: 20px;
        }
        .col0{
            font-size: 15px;
            font-weight: bold;
            padding: 10px;
            border-right: 1px solid #ebebeb;
            border-left: 1px solid #ebebeb;
        }
    }
    .three-page{
        .col{
            font-size: 17px;
            padding: 2px 10px;
        }

        .footers{
            margin-top: 450px;
            display: flex;
            justify-content: center;
            font-size: 14px;
            .footer {
                .text-footers{
                    margin: 10px;
                    padding: 15px;
                    border: 1px dotted #67C23A;
                }
                text-align: center;
                border: 1px solid #67C23A;
                width: 330px;
                div {
                    padding: 2px;
                }
            }
        }
    }
    .four-page{
        .col{
            font-size: 15px;
            font-weight: bold;
            padding: 10px;
            border-bottom: 1px solid #ebebeb;
            border-right: 1px solid #ebebeb;
            border-left: 1px solid #ebebeb;
        }

    }

    .five-page{
        .col-docder{
            border-top: 1px solid #67C23A;
            border-bottom: 1px solid #67C23A;
        }
        .zhjkpf{
            font-size: 12px;
            text-align: center;
            background-color: #ebebeb;
            padding: 30px;
            .zhjkpf-title{
                padding-top: 15px;
            }
            .zhjkpf-count{
                color: #67C23A;
                font-size: 25px;
                padding: 5px;
            }
        }
        .zhjkpf-right{
            border: 1px solid #ebebeb;
            padding: 10px;
            .imags{
                margin-top: 32px;
                display: flex;
                justify-content: center;
                img{
                    width: 30px;
                }
            }
            .zhjkpf-right-tag {
                display: flex;
                width: 100%;
                font-size: 14px;
                .xt {
                    width: 20%;
                }
                .xt01{
                    text-align: left;
                    padding-left: 10px;
                }
                .xt02{
                    text-align: center;
                }
                .xt03{
                    text-align: right;
                    padding-right: 20px;
                }
                .xt04{
                    text-align: right;
                    padding-right: 10px;
                }
                .xt05{
                    text-align: right;
                }
            }
            .zhjkpf-right-xt{
                display: flex;
                width: 100%;
                font-size: 14px;
                .xt{
                    width: 20%;
                    text-align: center;
                    color: #f7f7f7;
                    padding:5px 0;
                }
                .xt01{
                    background-color: #ca1213;
                }
                .xt02{
                    background-color: #f86531;
                }
                .xt03{
                    background-color: #ffb83a;
                }
                .xt04{
                    background-color: #6fba2c;
                }
                .xt05{
                    background-color: #359927;
                }
            }
        }
        .zhjkpf-text{
            border: 1px solid #ebebeb;
            padding: 10px;
            font-size: 14px;
            margin-top: 20px;
        }
        .zywxys-top{
            border-top: 1px solid #67C23A;
        }
        .zywxys-bottom{
            border-bottom: 1px solid #67C23A;
        }

    }
    .six-page{
        .pgjg-bar{
            border: 1px solid #ebebeb;
            padding: 10px;
        }
        .col{
            font-size: 15px;
            font-weight: bold;
            padding: 10px;
            border-bottom: 1px solid #ebebeb;
            border-right: 1px solid #ebebeb;
            border-left: 1px solid #ebebeb;
        }
        .zhjkpf-right{
            border: 1px solid #ebebeb;
            padding: 10px;
            .imags{
                margin-top: 22px;
                display: flex;
                justify-content: center;
                img{
                    width: 30px;
                }
            }
            .zhjkpf-right-tag {
                display: flex;
                width: 100%;
                font-size: 14px;
                .xt {
                    width: 25%;
                }
                .xt01{
                    text-align: left;
                    padding-left: 20px;
                }
                .xt02{
                    text-align: center;
                }
                .xt03{
                    text-align: right;
                    padding-right: 10px;
                }
                .xt04{
                    text-align: right;
                }
            }
            .zhjkpf-right-xt{
                display: flex;
                width: 100%;
                font-size: 14px;
                .xt{
                    width: 25%;
                    text-align: center;
                    color: #f7f7f7;
                    padding:5px 0;
                }
                .xt01{
                    background-color: #6fba2c;
                }
                .xt02{
                    background-color: #ffb83a;
                }
                .xt03{
                    background-color: #f86531;
                }
                .xt04{
                    background-color: #ca1213;

                }
            }
        }
        .pgjg-col{
            font-size:14px;
            ::v-deep .el-col{
                padding: 10px 0;
                border: 1px solid #ebebeb;
            }
            ::v-deep .el-col-4{
                text-align: center;
            }
            ::v-deep .el-col-8{
                background-color: #ebebeb;
            }
        }
        .pgjg-title-text{
            font-size: 12px;
            color: #4f9fe7;
            padding: 10px 0;
        }
    }
    .seven-page{
        .shfs{
            display: flex;
            align-items: center;
        }
        .shfs-title{
            padding: 10px;
            font-size: 15px;
        }
        .shfs-text{
            border: 1px solid #ebebeb;
            padding: 10px;
            font-size: 15px;
        }
        .zyfxjy{
            margin-right: 40px;
            font-size: 14px;
            .zyfxjy-title{
                color: #67C23A;
                padding: 10px 0;
            }
            .zyfxjy-title-text{
                background-color: #ebebeb;
                padding: 10px;
                .zyfxjy-small-title{
                    font-weight: bold;
                    padding-bottom: 10px;
                }
                .zyfxjy-text{
                    line-height: 20px;
                }
            }

        }
    }
    .eight-page{
        .shfs{
            display: flex;
            align-items: center;
            .label{
                padding-right: 10px;
                font-weight: bold;
            }
            .value{
                padding: 0 10px;
            }
        }
        .shfs-title{
            padding: 10px;
            font-size: 15px;
        }
        .col{
            font-size: 15px;
            font-weight: bold;
            padding: 10px;
            border-bottom: 1px solid #ebebeb;
            border-right: 1px solid #ebebeb;
            border-left: 1px solid #ebebeb;
        }
        .pgjg-title-text{
            font-size: 12px;
            color: #4f9fe7;
            padding: 10px 0;
            display: flex;
            align-items: center;
        }
        .ysxgfx{
            margin: 3px;
            text-align: center;
            background-color: #ebebeb;
            font-size: 14px;
            padding: 8px 20px;
            display: flex;
            align-items: center;
            img{
                padding-right: 5px;
            }
        }
        .yszysx{
            font-size: 14px;
            line-height: 25px;
            border: 1px solid #67C23A;
            padding: 10px 20px;
        }
        .swjh{
            img {
                width: 700px;
            }
        }
    }
    .nine-page{
        .shfs{
            font-size: 14px;
            display: flex;
            align-items: center;
            .label{
                width: 130px;
                font-weight: bold;
            }
            .value{
                padding: 0 5px;
                font-size: 13px;
            }
        }
        .shfs-title{
            padding: 10px;
            font-size: 15px;
            display: flex;
        }
        .col{
            font-size: 15px;
            font-weight: bold;
            padding: 10px;
            border-bottom: 1px solid #ebebeb;
            border-right: 1px solid #ebebeb;
            border-left: 1px solid #ebebeb;
        }
        .yszysx{
            font-size: 14px;
            line-height: 25px;
            border: 1px solid #67C23A;
            padding: 10px 20px;
        }
        .zhjkpf-right{
            padding: 10px 10px 250px 0;
            .zhjkpf-right-tag {
                display: flex;
                width: 100%;
                font-size: 14px;
                .xt {
                    width: 14.28%;
                }
                .xt01{
                    text-align: left;
                    padding-left: 20px;
                }
                .xt02{
                    text-align: left;
                    padding-left: 20px;
                }
                .xt03{
                    text-align: center;
                    padding-right: 40px;
                }
                .xt04{
                    text-align: center;
                    padding-right: 30px;
                }
                .xt05{
                    text-align: center;
                }
                .xt06{
                    text-align: right;
                    padding-right: 10px;
                }
                .xt07{
                    text-align: right;
                }
            }
            .zhjkpf-right-xt{
                display: flex;
                width: 100%;
                font-size: 14px;
                .xt{
                    width: 14.28%;
                    text-align: center;
                    color: #f7f7f7;
                    padding:5px 0;
                }
                .xt01{
                    background-color: #359927;
                }
                .xt02{
                    background-color: #6fba2c;
                }
                .xt03{
                    background-color: #dcdf9f;
                }
                .xt04{
                    background-color: #ffb83a;
                }
                .xt05{
                    background-color: #f88518;
                }
                .xt06{
                    background-color: #f86531;
                }
                .xt07{
                    background-color: #ca1213;
                }
            }

        }
        .pgjg-title-text{
            font-size: 12px;
            color: #4f9fe7;
            padding: 10px 0;
        }
    }
    .page-footer{
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        border-top: 1.5px solid black;
        text-align: center;
        z-index: 2; /* 底部内容的层叠顺序较低于页面内容 */
    }
    @media print {
        @page {
            margin-bottom: 30px; /* 在每一页的四周留出30px的边距 */
        }
        .echarts-container {
            width: 100% !important;
            height: auto !important;
        }
    }



</style>
