
export const dwList = [
    "地质研究所",
    "第四作业区",
    "第二作业区",
    "第三作业区",
    "生产维修大队",
    "第五作业区",
    "第七作业区",
    "第六作业区",
    "离退休职工管理中心",
    "保卫大队",
    "第一作业区",
    "技术保障中心",
    "油气加工六大队",
    "油气储运二大队",
    "公共课教学部（思想政治理论教学部、军事体育教学部）",
    "培训运行管理部",
    "数字化运维中心",
    "生产保障大队",
    "龙岗幼教总园",
    "东湖幼教总园",
    "让胡路幼教总园",
    "幼教管理部",
    "龙庆幼教总园\n",
    "乘风幼教总园",
    "银浪仓储分公司",
    "应急救援演练中心",
    "第九作业区",
    "中油电能",
    "第八作业区",
    "油气储运三大队",
    "油气加工二大队",
    "制水三公司",
    "污水运维管理公司",
    "防汛抢险基地",
    "制水二公司",
    "制水一公司",
    "南区销售公司",
    "油气加工九大队",
    "油气加工四大队",
    "萨尔图仓储分公司",
    "东区分公司",
    "喇区分公司",
    "综合协调部",
    "服务中心",
    "注采8-8班",
    "中区销售公司",
    "管网分公司",
    "再生资源分公司",
    "水务环保工程公司",
    "生产服务保障中心",
    "焊接培训中心",
    "物资装备中心",
    "技能人才培训部",
    "机电工程系",
    "南区分公司",
    "后勤管理中心"
]


export const grList = [
    {
        "id": "1656101602725568514",
        "name": "异常人群",
        "tags": null
    },
    {
        "id": "1656101887626670081",
        "name": "健康人群",
        "tags": null
    },
    {
        "id": "1656101949371019265",
        "name": "风险人群",
        "tags": null
    },
    {
        "id": "1656101988600344577",
        "name": "慢病人群",
        "tags": null
    },
    {
        "id": "1656102034699939842",
        "name": "大病人群",
        "tags": null
    },
    {
        "id": "1656107843580293121",
        "name": "大庆 - 健康人群",
        "tags": null
    },
    {
        "id": "1656107926083444737",
        "name": "大庆 - 风险人群",
        "tags": null
    },
    {
        "id": "1656107967836549122",
        "name": "大庆 - 异常人群",
        "tags": null
    },
    {
        "id": "1656108000585674754",
        "name": "大庆 - 慢病人群",
        "tags": null
    },
    {
        "id": "1656108216912289793",
        "name": "大庆 - 大病人群",
        "tags": null
    },
    {
        "id": "1686191385109041154",
        "name": "血糖偏高",
        "tags": null
    },
    {
        "id": "1686191413584171009",
        "name": "重中之重",
        "tags": null
    },
    {
        "id": "1686278558744571906",
        "name": "方法",
        "tags": null
    },
    {
        "id": "1691240362111479810",
        "name": "一级血压",
        "tags": null
    },
    {
        "id": "1701407592775815169",
        "name": "已评",
        "tags": null
    },
    {
        "id": "1705171785684643841",
        "name": "便潜血阳性",
        "tags": null
    },
    {
        "id": "1705200000947273730",
        "name": "高血脂",
        "tags": null
    },
    {
        "id": "1705200028984877058",
        "name": "脂肪肝",
        "tags": null
    },
    {
        "id": "1705394256964820994",
        "name": "高尿酸",
        "tags": null
    },
    {
        "id": "1705398115974017025",
        "name": "亚健康",
        "tags": null
    },
    {
        "id": "1705442858304356353",
        "name": "甲状腺结节4类",
        "tags": null
    },
    {
        "id": "1706106096384548865",
        "name": "甲状腺结节",
        "tags": null
    },
    {
        "id": "1706143680462614529",
        "name": "尿酸偏高",
        "tags": null
    },
    {
        "id": "1706145879750111234",
        "name": "肺结节",
        "tags": null
    },
    {
        "id": "1712316919816904706",
        "name": "高脂血症",
        "tags": null
    },
    {
        "id": "1712632776086511618",
        "name": "高血压病",
        "tags": null
    },
    {
        "id": "1712633121902682114",
        "name": "肾损伤",
        "tags": null
    },
    {
        "id": "1712641404384137217",
        "name": "饮酒，高甘油三酯，警惕胰腺炎",
        "tags": null
    },
    {
        "id": "1719171094525636609",
        "name": "慢病三级",
        "tags": null
    },
    {
        "id": "1719517708621647874",
        "name": "肥胖",
        "tags": null
    },
    {
        "id": "1722507868646215681",
        "name": "冠心病",
        "tags": null
    },
    {
        "id": "1722882028437344257",
        "name": "甘油三脂偏高",
        "tags": null
    },
    {
        "id": "1724236217409343489",
        "name": "血压偏高",
        "tags": null
    },
    {
        "id": "1724654813523709954",
        "name": "眼底动脉狭窄或出血",
        "tags": null
    },
    {
        "id": "1724654993128001537",
        "name": "心律失常",
        "tags": null
    },
    {
        "id": "1724662458150260738",
        "name": "颈动脉斑块或狭窄",
        "tags": null
    },
    {
        "id": "1724669552727457794",
        "name": "脑梗塞",
        "tags": null
    },
    {
        "id": "1724952290336411649",
        "name": "机关领导",
        "tags": null
    },
    {
        "id": "1724960654858215425",
        "name": "脾大",
        "tags": null
    },
    {
        "id": "1724960799863693314",
        "name": "甲状腺结节3类",
        "tags": null
    },
    {
        "id": "1725026220228427777",
        "name": "亚健康人群",
        "tags": null
    },
    {
        "id": "1725316323037405186",
        "name": "超重",
        "tags": null
    },
    {
        "id": "1731480109986160641",
        "name": "高血压2级拒治疗",
        "tags": null
    },
    {
        "id": "1731509480278827010",
        "name": "肺气肿",
        "tags": null
    },
    {
        "id": "1732644341612900354",
        "name": "已退休人员",
        "tags": null
    },
    {
        "id": "1732660710633725953",
        "name": "退休",
        "tags": null
    },
    {
        "id": "1732930980403847169",
        "name": "定时关注",
        "tags": null
    },
    {
        "id": "1732931431849807873",
        "name": "紧急关注",
        "tags": null
    },
    {
        "id": "1732931867554742273",
        "name": "适时关注",
        "tags": null
    },
    {
        "id": "1735186298741923842",
        "name": "重点关注",
        "tags": null
    },
    {
        "id": "1743072586666291202",
        "name": "血脂异常，其他疾病已排除",
        "tags": null
    },
    {
        "id": "1752957818001920001",
        "name": "甲状腺肿瘤术后",
        "tags": null
    },
    {
        "id": "1753217113146077186",
        "name": "肝癌",
        "tags": null
    },
    {
        "id": "1753273205880336385",
        "name": "重症肌无力、胸腺瘤术后",
        "tags": null
    },
    {
        "id": "1759105350486011905",
        "name": "肺部肿瘤术后",
        "tags": null
    },
    {
        "id": "1760917689994055682",
        "name": "肠道肿瘤术后（惰性肿瘤）",
        "tags": null
    },
    {
        "id": "1761920210273402881",
        "name": "淋巴瘤",
        "tags": null
    },
    {
        "id": "1762746811290959874",
        "name": "2024配合度不佳",
        "tags": null
    },
    {
        "id": "1770618376698826753",
        "name": "血压平稳",
        "tags": null
    },
    {
        "id": "1775414958125166593",
        "name": "左肾恶性肿瘤术后",
        "tags": null
    },
    {
        "id": "1775415593717411841",
        "name": "左乳腺肿瘤术后",
        "tags": null
    },
    {
        "id": "1780475193763655682",
        "name": "肾移植术后",
        "tags": null
    },
    {
        "id": "1780509524783878146",
        "name": "肾癌、右肾切除",
        "tags": null
    },
    {
        "id": "1784763980299599874",
        "name": "肝移植",
        "tags": null
    },
    {
        "id": "1789842627006169090",
        "name": "去世",
        "tags": null
    },
    {
        "id": "1790558776048222210",
        "name": "肾小球肾炎",
        "tags": null
    },
    {
        "id": "1792726531114942465",
        "name": "肺磨玻璃结节",
        "tags": null
    },
    {
        "id": "1792727718341611522",
        "name": "肺磨玻璃结节5-8mm",
        "tags": null
    },
    {
        "id": "1792742281543233538",
        "name": "甲状腺肿瘤",
        "tags": null
    },
    {
        "id": "1792747552077398017",
        "name": "乳腺癌术后",
        "tags": null
    },
    {
        "id": "1792747722462609409",
        "name": "乳腺癌",
        "tags": null
    },
    {
        "id": "1792785420254154754",
        "name": "子宫内膜癌术后",
        "tags": null
    },
    {
        "id": "1792786040255492098",
        "name": "子宫颈癌",
        "tags": null
    },
    {
        "id": "1792787360287965185",
        "name": "子宫内膜癌",
        "tags": null
    },
    {
        "id": "1792788973009317890",
        "name": "白血病",
        "tags": null
    },
    {
        "id": "1792789299928330242",
        "name": "淋巴癌",
        "tags": null
    },
    {
        "id": "1792789674201595905",
        "name": "再生障碍性贫血",
        "tags": null
    },
    {
        "id": "1792789869139824642",
        "name": "系统性红斑狼疮",
        "tags": null
    },
    {
        "id": "1792792142096076802",
        "name": "睾丸癌",
        "tags": null
    },
    {
        "id": "1792792502715641858",
        "name": "精原细胞癌",
        "tags": null
    },
    {
        "id": "1792792733497135105",
        "name": "膀胱癌",
        "tags": null
    },
    {
        "id": "1792793267630862338",
        "name": "前列腺癌",
        "tags": null
    },
    {
        "id": "1792793505836273666",
        "name": "肾癌",
        "tags": null
    },
    {
        "id": "1792794371633332225",
        "name": "肾细胞癌",
        "tags": null
    },
    {
        "id": "1792794872666992642",
        "name": "肾功能衰竭",
        "tags": null
    },
    {
        "id": "1792796464036261890",
        "name": "结肠癌",
        "tags": null
    },
    {
        "id": "1792797433076355073",
        "name": "食管癌",
        "tags": null
    },
    {
        "id": "1792798147347050497",
        "name": "胰腺癌",
        "tags": null
    },
    {
        "id": "1792798313964519426",
        "name": "直肠癌",
        "tags": null
    },
    {
        "id": "1792798797566652417",
        "name": "脑肿瘤",
        "tags": null
    },
    {
        "id": "1792799012839985154",
        "name": "神经内分泌癌",
        "tags": null
    },
    {
        "id": "1792802168887668738",
        "name": "头部基底细胞癌",
        "tags": null
    },
    {
        "id": "1792802578851524609",
        "name": "上额窦癌",
        "tags": null
    },
    {
        "id": "1792803045910065154",
        "name": "粘液软骨肉瘤",
        "tags": null
    },
    {
        "id": "1793087451271938050",
        "name": "脑出血",
        "tags": null
    },
    {
        "id": "1793159378314555393",
        "name": "尿毒症",
        "tags": null
    },
    {
        "id": "1793176762822135810",
        "name": "肺微结节",
        "tags": null
    },
    {
        "id": "1793176951645945857",
        "name": "肝硬化",
        "tags": null
    },
    {
        "id": "1793897653750415362",
        "name": "肺结节大于8mm",
        "tags": null
    },
    {
        "id": "1796374204112441345",
        "name": "右手断指术后",
        "tags": null
    },
    {
        "id": "1796374329533767681",
        "name": "下壁心梗介入术后",
        "tags": null
    },
    {
        "id": "1797537194071769090",
        "name": "已调离",
        "tags": null
    },
    {
        "id": "1797883088938295297",
        "name": "肺癌",
        "tags": null
    },
    {
        "id": "1798234939214884865",
        "name": "抑郁症长期服药",
        "tags": null
    },
    {
        "id": "1798236716857389057",
        "name": "甲状腺恶性肿瘤术后",
        "tags": null
    },
    {
        "id": "1798237035867762690",
        "name": "抑郁症",
        "tags": null
    },
    {
        "id": "1798237166483767297",
        "name": "心脏支架术后",
        "tags": null
    },
    {
        "id": "1798966006839476225",
        "name": "已故人员",
        "tags": null
    },
    {
        "id": "1800328555446857729",
        "name": "已经调离天然气",
        "tags": null
    },
    {
        "id": "1800330221132476417",
        "name": "已回原单位",
        "tags": null
    },
    {
        "id": "1800339040415805441",
        "name": "类风险性关节炎",
        "tags": null
    },
    {
        "id": "1800339209528471554",
        "name": "类风湿性关节炎",
        "tags": null
    }
]


export const pgList = [
    {
        "id": "0",
        "name": "风险等级",
        "tags": [
            {
                "name": "健康人群",
            },
            {
                "name": "风险人群",
            },
            {
                "name": "异常人群",
            },
            {
                "name": "慢病人群",
            },
            {
                "name": "大病人群",
            }
        ]
    },
    {
        "name": "血压异常",
        "tags": [
            {
                "name": "一级",
            },
            {
                "name": "二级",
            },
            {
                "name": "三级",
            }
        ]
    },
    {
        "name": "血脂异常",
        "tags": [
            {
                "name": "一级",
            },
            {
                "name": "二级",
            },
            {
                "name": "三级",
            }
        ]
    },
    {
        "name": "血糖异常",
        "tags": [
            {
                "name": "一级",
            },
            {
                "name": "二级",
            },
            {
                "name": "三级",
            }
        ]
    },
    // {
    //     "id": "1657479057784807425",
    //     "name": "高同型半胱氨酸异常",
    //     "tags": [
    //         {
    //             "id": "1657479057814167554",
    //             "name": "高同型半胱氨酸:轻度",
    //             "groupId": "1657479057784807425",
    //             "groupName": "高同型半胱氨酸异常",
    //             "isLeaf": true
    //         },
    //         {
    //             "id": "1657481459598163970",
    //             "name": "高同型半胱氨酸:重度",
    //             "groupId": "1657479057784807425",
    //             "groupName": "高同型半胱氨酸异常",
    //             "isLeaf": true
    //         },
    //         {
    //             "id": "1661042111741296641",
    //             "name": "高同型半胱氨酸:中度",
    //             "groupId": "1657479057784807425",
    //             "groupName": "高同型半胱氨酸异常",
    //             "isLeaf": true
    //         }
    //     ]
    // },
    // {
    //     "id": "1657945064902819841",
    //     "name": "其它高危疾病",
    //     "tags": [
    //         {
    //             "id": "1657945064932179970",
    //             "name": "急性胰腺炎",
    //             "groupId": "1657945064902819841",
    //             "groupName": "其它高危疾病",
    //             "isLeaf": true
    //         },
    //         {
    //             "id": "1657946795238072321",
    //             "name": "哮喘",
    //             "groupId": "1657945064902819841",
    //             "groupName": "其它高危疾病",
    //             "isLeaf": true
    //         },
    //         {
    //             "id": "1657976462355570689",
    //             "name": "冠心病(心绞痛、心肌梗塞、冠状动脉粥样硬化性心脏病等)",
    //             "groupId": "1657945064902819841",
    //             "groupName": "其它高危疾病",
    //             "isLeaf": true
    //         },
    //         {
    //             "id": "1657997328116195329",
    //             "name": "心律不齐(室性心律不齐、室上性心律不齐、心房纤颤、房室传导阻滞)",
    //             "groupId": "1657945064902819841",
    //             "groupName": "其它高危疾病",
    //             "isLeaf": true
    //         },
    //         {
    //             "id": "1658089124883415042",
    //             "name": "冠脉综合征",
    //             "groupId": "1657945064902819841",
    //             "groupName": "其它高危疾病",
    //             "isLeaf": true
    //         },
    //         {
    //             "id": "1661030665683308546",
    //             "name": "高血压视网膜病变",
    //             "groupId": "1657945064902819841",
    //             "groupName": "其它高危疾病",
    //             "isLeaf": true
    //         },
    //         {
    //             "id": "1661030665687502849",
    //             "name": "脂肪肝",
    //             "groupId": "1657945064902819841",
    //             "groupName": "其它高危疾病",
    //             "isLeaf": true
    //         },
    //         {
    //             "id": "1661030665687502850",
    //             "name": "慢性阻塞性肺病",
    //             "groupId": "1657945064902819841",
    //             "groupName": "其它高危疾病",
    //             "isLeaf": true
    //         },
    //         {
    //             "id": "1661030665691697154",
    //             "name": "冠脉支架或搭桥术后",
    //             "groupId": "1657945064902819841",
    //             "groupName": "其它高危疾病",
    //             "isLeaf": true
    //         },
    //         {
    //             "id": "1661030665695891457",
    //             "name": "脑动脉瘤",
    //             "groupId": "1657945064902819841",
    //             "groupName": "其它高危疾病",
    //             "isLeaf": true
    //         },
    //         {
    //             "id": "1661030665695891458",
    //             "name": "恶性肿瘤",
    //             "groupId": "1657945064902819841",
    //             "groupName": "其它高危疾病",
    //             "isLeaf": true
    //         },
    //         {
    //             "id": "1661030665695891459",
    //             "name": "心脏起搏器植入术后",
    //             "groupId": "1657945064902819841",
    //             "groupName": "其它高危疾病",
    //             "isLeaf": true
    //         },
    //         {
    //             "id": "1661030665695891460",
    //             "name": "重度睡眠呼吸暂停综合征",
    //             "groupId": "1657945064902819841",
    //             "groupName": "其它高危疾病",
    //             "isLeaf": true
    //         },
    //         {
    //             "id": "1661030665695891461",
    //             "name": "下肢深静脉血栓",
    //             "groupId": "1657945064902819841",
    //             "groupName": "其它高危疾病",
    //             "isLeaf": true
    //         },
    //         {
    //             "id": "1661030665695891462",
    //             "name": "脑梗塞",
    //             "groupId": "1657945064902819841",
    //             "groupName": "其它高危疾病",
    //             "isLeaf": true
    //         },
    //         {
    //             "id": "1661030665700085762",
    //             "name": "主动脉夹层",
    //             "groupId": "1657945064902819841",
    //             "groupName": "其它高危疾病",
    //             "isLeaf": true
    //         },
    //         {
    //             "id": "1661030665700085763",
    //             "name": "颈动脉斑块或狭窄",
    //             "groupId": "1657945064902819841",
    //             "groupName": "其它高危疾病",
    //             "isLeaf": true
    //         },
    //         {
    //             "id": "1661030665700085764",
    //             "name": "心律失常",
    //             "groupId": "1657945064902819841",
    //             "groupName": "其它高危疾病",
    //             "isLeaf": true
    //         },
    //         {
    //             "id": "1661030665700085765",
    //             "name": "肺栓塞",
    //             "groupId": "1657945064902819841",
    //             "groupName": "其它高危疾病",
    //             "isLeaf": true
    //         },
    //         {
    //             "id": "1661030665700085766",
    //             "name": "短暂性脑缺血发作",
    //             "groupId": "1657945064902819841",
    //             "groupName": "其它高危疾病",
    //             "isLeaf": true
    //         },
    //         {
    //             "id": "1661030665700085767",
    //             "name": "脑出血",
    //             "groupId": "1657945064902819841",
    //             "groupName": "其它高危疾病",
    //             "isLeaf": true
    //         },
    //         {
    //             "id": "1661030665700085768",
    //             "name": "眼底动脉狭窄或出血",
    //             "groupId": "1657945064902819841",
    //             "groupName": "其它高危疾病",
    //             "isLeaf": true
    //         },
    //         {
    //             "id": "1661030665700085769",
    //             "name": "冠心病",
    //             "groupId": "1657945064902819841",
    //             "groupName": "其它高危疾病",
    //             "isLeaf": true
    //         },
    //         {
    //             "id": "1672937291818303489",
    //             "name": "高脂血症",
    //             "groupId": "1657945064902819841",
    //             "groupName": "其它高危疾病",
    //             "isLeaf": true
    //         }
    //     ]
    // },
    {
        "id": "1657976932461248514",
        "name": "尿酸异常",
        "tags": [
            {
                "name": "一级",
            },
            {
                "name": "二级",
            },
            {
                "name": "三级",
            }
        ]
    },
    {
        "name": "体重异常",
        "tags": [
            {
                "name": "一级",
            },
            {
                "name": "二级",
            },
            {
                "name": "三级",
            }
        ]
    }
]


export const dbList = [
    {
        "name": "高血压",
        "tags": [
            {
                "name": "一级",
                "value": "高血压:一级",
            },
            {
                "name": "二级",
                "value": "高血压:二级",
            },
            {
                "name": "三级",
                "value": "高血压:三级",
            }
        ]
    },
    {
        "name": "高血脂",
        "tags": [
            {
                "name": "一级",
                "value": "高血脂:一级",
            },
            {
                "name": "二级",
                "value": "高血脂:二级",
            },
            {
                "name": "三级",
                "value": "高血脂:三级",
            }
        ]
    },
    {
        "name": "高血糖",
        "tags": [
            {
                "name": "一级",
                "value": "高血糖:一级",
            },
            {
                "name": "二级",
                "value": "高血糖:二级",
            },
            {
                "name": "三级",
                "value": "高血糖:三级",
            }
        ]
    },
    {
        "name": "高尿酸",
        "tags": [
            {
                "name": "一级",
                "value": "高尿酸:一级",
            },
            {
                "name": "二级",
                "value": "高尿酸:二级",
            },
            {
                "name": "三级",
                "value": "高尿酸:三级",
            }
        ]
    },
    {
        "name": "高BMI",
        "tags": [
            {
                "name": "一级",
                "value": "高BMI:一级",
            },
            {
                "name": "二级",
                "value": "高BMI:二级",
            },
            {
                "name": "三级",
                "value": "高BMI:三级",
            }
        ]
    }
]
