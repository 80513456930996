<template>
    <div class="page">
        <div class="header">
            <div class="btns">
                <el-button size="mini" @click="getData" icon="el-icon-refresh">刷新</el-button>
            </div>
            <el-page-header @back="$router.go(-1)" content="健康筛查评估" />
        </div>
        <div class="search">
            <el-form :inline="true" :model="search" size="small" label-width="50px">
<!--                <el-form-item label="工作区" label-width="70px">-->
<!--                    <el-select v-model="search.wpName" placeholder="请选择" style="width:100%" clearable>-->
<!--                        <el-option label="全部" value="全部"></el-option>-->
<!--                        <el-option :label="item.name" :value="item.name" v-for="(item, index) in gzqList"-->
<!--                                   :key="index"></el-option>-->
<!--                    </el-select>-->
<!--                </el-form-item>-->
                <el-form-item label="工作单位" label-width="70px">
                    <cascader-company-name v-model="search.companyName"></cascader-company-name>
                </el-form-item>
                <el-form-item label="性别">
                    <el-select v-model="search.sex" placeholder="请选择" style="width:130px" clearable>
                        <el-option label="全部" value="全部"></el-option>
                        <el-option label="男" value="1"></el-option>
                        <el-option label="女" value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="年龄">
                    <el-select v-model="search.age" placeholder="请选择" style="width:100%" clearable>
                        <el-option label="全部" value="全部"></el-option>
                        <el-option label="35岁以下" value="1"></el-option>
                        <el-option label="35岁至45岁" value="2"></el-option>
                        <el-option label="45岁至50岁" value="3"></el-option>
                        <el-option label="50岁以上" value="4"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="搜索" >
                    <el-input placeholder="请输入内容" v-model="search.value" class="input-with-select" clearable>
                        <el-select v-model="search.key" slot="prepend" placeholder="请选择" style="width:90px">
                            <el-option label="名称" value="name"></el-option>
                            <el-option label="身份证" value="idCard"></el-option>
                        </el-select>
                    </el-input>
                </el-form-item>
                <el-form-item label="激活状态" label-width="70px">
                    <el-select v-model="search.activeStatus" placeholder="请选择" style="width:100%" clearable>
                        <el-option label="全部" value="全部"></el-option>
                        <el-option label="未激活" value="0"></el-option>
                        <el-option label="已激活" value="1"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="评估问卷" label-width="70px">
                    <el-select v-model="search.questionnaireStatus" placeholder="请选择" style="width:100%" clearable>
                        <el-option label="全部" value="全部"></el-option>
                        <el-option label="已回填" value="0"></el-option>
                        <el-option label="未回填" value="1"></el-option>
                        <el-option label="未发送" value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="评估状态" label-width="70px">
                    <el-select v-model="search.status" placeholder="请选择" style="width:100%" clearable>
                        <el-option label="全部" value="全部"></el-option>
                        <el-option label="已完成" value="2"></el-option>
                        <el-option label="待审核" value="1"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="HRA风险等级" label-width="100px">
                    <el-select v-model="search.risklevel" placeholder="请选择" style="width:100%" clearable>
                        <el-option label="HRA-紧急关注人群" value="HRA-紧急关注人群"></el-option>
                        <el-option label="HRA-重点关注人群" value="HRA-重点关注人群"></el-option>
                        <el-option label="HRA-定时关注人群" value="HRA-定时关注人群"></el-option>
                        <el-option label="HRA-健康良好人群" value="HRA-健康良好人群"></el-option>
                        <el-option label="HRA-适时关注人群" value="HRA-适时关注人群"></el-option>
                        <el-option label="HRA-亚健康人群" value="HRA-亚健康人群"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="HRA单病风险" label-width="100px">
                    <el-cascader :options="dbList" :props="defaultProps" collapse-tags clearable v-model="search.risklevelList"></el-cascader>
                </el-form-item>
                <el-form-item label="标签分组" label-width="70px">
                    <el-select v-model="search.KHBQ" placeholder="请选择" style="width:100%" filterable allow-create
                        default-first-option clearable>
                        <el-option label="全部" value="全部"></el-option>
                        <el-option :label="item.name" :value="item.name" v-for="(item, index) in tagList"
                            :key="index"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" plain @click="getData">查询</el-button>
                    <el-button type="" plain @click="reset">重置</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="search" style="background-color: #fff">
            <div class="btns">
                <el-button type="primary" size="mini" @click="handleSendQuestionnaire"
                           :disabled="idList.length ? false : true">发送评估问卷
                </el-button>
                <el-button type="primary" size="mini" @click="handleAdd" :disabled="idList.length ? false : true">批量催办
                </el-button>
            </div>
        </div>
        <div class="main-content">
            <div class="table-content">
                <el-table :data="table.data" stripe size="small" v-loading="loading" width="100%" height="100%" border
                    @selection-change="handleSelectionChange">
                    <el-table-column type="selection" width="55">
                    </el-table-column>
                    <el-table-column type="index" label="序号" align="center"
                        :index="(index) => { return (this.table.page - 1) * this.table.pageSize + index + 1 }"
                        width="55" />
                    <el-table-column align="center" label="基本信息" width="160">
                        <template slot-scope="scope">
                            <div>
                                <span>{{ scope.row.name }}</span>
                                <span v-if="scope.row.sex == '2'"> 女 </span>
                                <span v-if="scope.row.sex == '1'"> 男 </span>
                                <span v-if="scope.row.age">{{ scope.row.age }} 岁</span>
                            </div>
                            <div><small> {{ maskIdCardRegex(scope.row.idCard) }}</small>
                                <i class="el-icon-view" style="cursor: pointer;padding-left: 10px"
                                    @click="toggleIdCard(scope.$index)"></i>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="客户标签" width="100">
                        <template slot-scope="scope">
                            <popover-JCXM :tagList="scope.row" />
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="联系方式" width="200">
                        <template slot-scope="scope">
                            <div>{{ scope.row.companyName }}</div>
                            <div>{{ scope.row.mobile }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="激活状态" width="" prop="activeStatus">
                        <template slot-scope="scope">
                            <el-tag v-if="scope.row.activeStatus" type="success">已激活</el-tag>
                            <el-tag v-else type="info">未激活</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="问卷回填" prop="questionnaire_status" width="150">
                        <template slot-scope="scope">
                            <el-tag v-if="scope.row.questionnaire_status === '3'" type="success">已填写</el-tag>
                            <el-tag v-else-if="scope.row.questionnaire_status === '2'" type="warning">已超时未填写</el-tag>
                            <el-tag v-else-if="scope.row.questionnaire_status === '1'" type="warning">已发送未填写</el-tag>
                            <el-tag v-else type="info">未发送</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="评估状态" width="140" prop="status">
                        <template slot-scope="scope">
                            <el-tag v-if="scope.row.status == '1'">已完成</el-tag>
                            <el-tag v-else>未开始</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="评估方式" width="140" prop="type">
                        <template slot-scope="scope">
                            <el-tag v-if="scope.row.type == '1'">人工评估</el-tag>
                            <el-tag v-if="scope.row.type == '2'">问卷评估</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="最近评估" prop="evaluation_date" width="140">
                    </el-table-column>
                    <el-table-column align="center" label="当前风险等级" prop="current_risk_level" width="140">
                    </el-table-column>
                    <el-table-column align="center" label="最新风险等级" prop="latest_risk_level" width="140">
                    </el-table-column>
                    <el-table-column align="center" width="240" label="操作" fixed="right">
                        <template slot-scope="scope">
                            <el-button type="text" size="mini" @click="handleRowInfo(scope.row)">评估详情</el-button>
                            <el-button type="text" size="mini" @click="handleRowChange(scope.row)">健康档案</el-button>
                            <el-button type="text" size="mini" @click="handleRowRgpg(scope.row)">人工评估</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <data-form :visible="dialogVisible" :form="form" @close="dialogVisible = false" @update="getData"></data-form>
        <rgpg-form :visible="rgpgVisible" :form="form" @close="rgpgVisible = false" @update="getData"></rgpg-form>
        <pgreport :visible="pgreportVisiable" :form="form" @close="pgreportVisiable = false" />
        <div class="pager" v-if="table.total > table.pageSize">
            <el-pagination layout="total, prev, pager, next, sizes" :page-sizes="[10, 20, 30, 50, 100, 200, 500, 1000]"
                :current-page="table.page" :total="table.total" :page-size="table.pageSize"
                @current-change="handlePageChange" @size-change="handlePageSizeChange" />
        </div>
    </div>
</template>

<script>
import data from './data'
import formatter from "../../../data/formatter"
import dataForm from "./widgets/dataform";
import rgpgForm from "./widgets/rgpgform";
import pgreport from "./widgets/pgreport";
import { dbList } from "../../../data/utlisData"

const makeInitSearch = () => {
    return {
        activeStatus: "",
        key: "name",
        value: "",
        wpName:"",
        sex:"",
        age:"",
        status:"",
        risklevel:"",
        risklevelList:[],
        questionnaireStatus:""
    }
}


export default {
    name: 'progress3',
    components: {
        dataForm,
        rgpgForm,
        pgreport,
    },
    data() {
        return {
            gzqList: [],
            dbList: dbList,
            defaultProps: {
                multiple: true,
                children: 'tags',
                label: 'name',
                value: 'name'
            },
            isFullIdCard: {},
            tagList: [
                {
                    id: 1,
                    name: "五高二级"
                },
                {
                    id: 2,
                    name: "四高二级"
                },
                {
                    id: 3,
                    name: "三高二级"
                },
                {
                    id: 4,
                    name: "二高二级"
                },
                {
                    id: 5,
                    name: "一高二级"
                },
            ],
            form: {
                id: null,
                name: "",
                age: "",
                sex: "",
                idCard: "",
                nation: "",
                marital: "",
                guardian: "",
                ecmobile: "",
                companyName: "",
                workType: "",
                blood: "",
                companyJob: "",
            },
            rgpgVisible: false,
            dialogVisible: false,
            pgreportVisiable: false,
            formatter: formatter,
            loading: false,
            search: makeInitSearch(),
            table: data.table,
            idList: []
        }
    },
    methods: {
        handleSendQuestionnaire() {
            this.$confirm(`确认要发送评估问卷吗？`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.loading = true
                this.$http.post(`/healthAssessment/jksc/send`, { userIdList: this.idList })
                    .then(resp => {
                        this.$message[resp.data.status](resp.data.msg)
                        if (resp.data.status == 'success') this.getData()
                    }).catch(err => {
                    this.$message.error(err.message)
                }).finally(() => {
                    this.loading = false
                })
            }).catch(() => {
            });
        },
        handleRowRgpg(row) {
            this.form= row
            this.dialogVisible = true
        },
        handleRowInfo(row) {
            this.pgreportVisiable = true
            this.form = row
        },
        maskIdCardRegex(idCard) {
            return idCard.replace(/^(.{4})(.*)(.{4})$/, function (match, p1, p2, p3) {
                return p1 + p2.replace(/./g, '*') + p3;
            });
        },
        toggleIdCard(index) {
            // 切换显示状态
            this.$set(this.isFullIdCard, index, !this.isFullIdCard[index]);
        },
        handleAdd() {
            this.form.KHBQ = []
            this.dialogVisible = true
        },
        reset() {
            this.search = makeInitSearch()
            setTimeout(() => {
                this.getData()
            }, 0);
        },
        handleRowChange(row) {
            this.$router.push({ path: `/main/phr/detail/${row.id}` })
        },
        handleSelectionChange(val) {
            var that = this
            that.idList = val
            // if (val.length) {
            //     val.forEach(function (item) {
            //         that.form.idList.push(item.id)
            //     })
            // }
        },
        handlePageChange(e) {
            this.table.page = e
            this.getData()
        },
        handlePageSizeChange(e) {
            this.table.pageSize = e
            this.getData()
        },
        async getData() {
            this.dialogVisible = false
            this.loading = true
            const resp = await this.$http.get(`/healthAssessment/jksc?page=${this.table.page}&pageSize=${this.table.pageSize}`, { params: this.search })
            if (!resp.data) {
                this.$message.error(resp.data.msg)
            } else {
                this.table.data = resp.data.data
                this.table.total = resp.data.total
            }
            this.loading = false
        },
        async getUserOwn() {
            const resp = await this.$http.get(`/userown?page=${this.table.page}&pageSize=500`)
            if (!resp.data) {
                this.$message.error(resp.data.msg)
            } else {
                this.gzqList = resp.data.data
            }
        },
    },
    mounted() {
        this.getUserOwn()
        this.getData()
    }
}
</script>

<style lang="scss" scoped>
.page {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.header {
    padding-bottom: 10px;

    .btns {
        float: right;
    }
}

.search {
    padding: 10px;
    background-color: #FAFAFA;
    border-top: 1px solid #EBEEF5;
    border-left: 1px solid #EBEEF5;
    border-right: 1px solid #EBEEF5;

    ::v-deep .el-form-item {
        padding: 5px !important;
        margin-bottom: 5px !important;
    }

    .btns {
        float: right;
    }
}

.main-content {
    flex: 1;
    position: relative;
}

.table-content {
    position: absolute;
    width: 100%;
    height: 100%;
}

.pager {
    padding-top: 10px;
    text-align: center;
}
</style>
