import { render, staticRenderFns } from "./pgreport.vue?vue&type=template&id=25810943&scoped=true&"
import script from "./pgreport.vue?vue&type=script&lang=js&"
export * from "./pgreport.vue?vue&type=script&lang=js&"
import style0 from "./pgreport.vue?vue&type=style&index=0&id=25810943&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25810943",
  null
  
)

export default component.exports