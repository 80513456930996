<template>
    <div style="width:100%">
        <v-chart autoresize :option="option" :style="{width:'100%',height:'200px'}" v-loading="loading" ref="vChartInstance"></v-chart>
    </div>
</template>

<script>
    export default {
        name: 'expertBar',
        props: {
            bardata: {
                default: []
            },
        },
        data() {
            return {
                loading: false,
                daterange: [],
                option: {
                    grid: {
                        left: '1%',
                        right: '0%',
                        top: '10%',
                        bottom: '10%',
                    },
                    title: {
                        text: "生活方式评分",
                        subtext: "72.50",
                        x: 'center',
                        // y: 'center',
                        top:"70px",
                        textStyle: {
                            fontSize: 13,
                            color:'#8c8a88',
                        },
                        subtextStyle: {
                            fontSize: 30,
                            color:"#f1b839"
                        }
                    },
                    color:'#ebebeb',
                    series: [{
                        type: 'pie',
                        clockWise: true,
                        radius: ['70%', '83%'],
                        itemStyle: {
                            normal: {
                                label: {
                                    show: false
                                },
                                labelLine: {
                                    show: false
                                }
                            }
                        },
                        hoverAnimation: false,
                        data: [{
                            value: 72.50,
                            itemStyle: {
                                normal: {
                                    color: { // 完成的圆环的颜色
                                        colorStops: [{
                                            offset: 0,
                                            color: '#f1b839' // 0% 处的颜色
                                        }, {
                                            offset: 1,
                                            color: '#74b92c' // 100% 处的颜色
                                        }]
                                    },
                                    label: {
                                        show: false
                                    },
                                    labelLine: {
                                        show: false
                                    }
                                }
                            }
                        }, {
                            value: 100-72.50+10
                        }]
                    }]
                }
            }
        },
    }
</script>

<style>
    @media print {
        .echarts-container {
            width: 100% !important;
            height: auto !important;
        }
    }
</style>
