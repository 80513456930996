<template>
    <div style="width:100%">
        <v-chart autoresize :option="option" style="height: 170px" v-loading="loading"></v-chart>
    </div>
</template>

<script>
    export default {
        name: 'expertBar',
        props: {
            bardata: {
                default: []
            },
        },
        data() {
            return {
                loading: false,
                daterange: [],
                option: {
                    grid: {
                        left: '10%',
                        right: '0%',
                        top: '10%',
                        bottom: '12%',
                    },
                    xAxis: {
                        type: 'category',
                        data: [
                            '最佳状态',
                            '风险程度',
                            '平均风险',
                        ],
                        axisTick: {
                            show: false
                        },
                        axisLine:{
                            lineStyle:{
                                color:"#ebebeb"//x轴轴线颜色
                            }
                        },
                        axisLabel: {//y轴文字的配置
                            textStyle: {
                                color: "black",
                                margin: 15
                            },
                        },
                    },
                    yAxis: {
                        type: 'value',
                        interval:6
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow'
                        }
                    },
                    series: [
                        {
                            data: [10.48, 28.36, 0.3],
                            type: 'bar',
                            barWidth: '40%',
                            itemStyle: {
                                normal: {
                                    //这里是重点
                                    color: function(params) {
                                        //注意，如果颜色太少的话，后面颜色不会自动循环，最好多定义几个颜色
                                        var colorList = ['#6bb053','#f86531', '#8693db'];
                                        return colorList[params.dataIndex]
                                    }
                                }
                            }
                        }
                    ]
                }
            }
        },
        methods: {},
        mounted() {
            // this.getData()
        }
    }
</script>

<style>
    @media print {
        .echarts-container {
            width: 100% !important;
            height: auto !important;
        }
    }
</style>
